/* Open Sans Font */
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

/* Open Sans Condensed Font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Open+Sans:wght@300;400;500;600&display=swap');

@import './sassStyles/variables';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lightgray;
  font-family: 'Open Sans', Times, serif;
}

main {
  background: linear-gradient(0deg, rgba(83, 19, 151, 1) 0%, rgba(52, 97, 206, 1) 100%);
  padding-bottom: 100px;
  padding-top: 120px;
  overflow: auto;
  height: 100vh;
}

p {
  margin-bottom: 2px;
}

.negative {
  color: $tradeview-negative !important;
}

.positive {
  color: $tradeview-positive;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

@media (max-width: 800px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7367F0;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #10163A;
}