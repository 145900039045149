@import '../../../sassStyles/mixins';
@import '../../../sassStyles/typography';
@import '../../../sassStyles/variables';
@import "https://code.highcharts.com/css/highcharts.css";

:root {
  /* light mode */
  --background-color: #161522;
  --text-color: #858585;
  --hilight-color: #0275d8;
}

.graph-section {
	display: flex;
	flex-direction: column;
	height: 100%;

	&-error {
		display: flex;
		flex-direction: column;
		color: white;
		height: 100%;
		justify-content: center;
    	padding: 10px;
	}

  &-title {
    	display: flex;
		align-items: flex-end;
		color: rgb(197, 197, 197);
		font-family: sans-serif;
		margin-bottom: 20px;
		justify-content: space-between;

		&-one {
			display: flex;
			align-items: center;

			.info-one {
				font-size: $font-size-large-more;
				margin-left: 15px;
				margin-right: 5px;
				display: flex;
				align-items: flex-end;

				&--item {
					padding-bottom: 5px;
					margin-right: 10px;

					.icon {
						width: 30px;
						height: 30px;
					}
				}
			}

			.info-two {
				color: white;
				font-size: 30px;
				align-items: center;
				margin-left: 10px;
			}
		}

    .info-three {
			display: flex;
			font-size: $font-size-large-more;
    }
  }

	&-date {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background: #161522;
		align-items: center;
		padding-top: 10px;
		padding-left: 10px;

		.timeframe {
			position: relative;
			margin-top: 5px;

			input[type="radio"] {
				position: absolute;
				opacity: 0;
			}

			.range {
				-webkit-transition: all 0.2s;
				transition: all 0.2s;
			}

			.range {
				cursor: pointer;
				padding: 0px 15px;
				background: #202231;
				color: white;
				margin-right: 5px;
				border-radius: 5px;

				@media (max-width: 800px) {
					padding: 0px 10px;
				}
			}

			input[class="range"]:hover + .range,
			input[class="range"]:checked + .range,
			input[class="range"]:focus + .range {
				background: #5C83AD;
			}
		}

		.date-text {
			color: white;
			display: flex;
			margin-right: 20px;
			color: #0275d8;

			.start-date {
				margin-right: 5px;
			}

			.end-date {
				margin-left: 5px;
			}
		}
	}

  body {
    background-color: var(--background-color);
  }
  .highcharts-background {
    fill: var(--background-color);
  }
  .highcharts-container text {
    fill: var(--text-color);
  }
  .highcharts-subtitle,
  .highcharts-credits,
  .highcharts-axis-title {
    fill-opacity: 0.7;
  }
  .highcharts-grid-line {
    stroke: var(--text-color);
    stroke-opacity: 0.2;
  }
  .highcharts-tooltip-box {
    fill: var(--background-color);
  }
  .highcharts-column-series rect.highcharts-point {
    stroke: var(--background-color);
  }
  .highcharts-scrollbar-track {
    fill: transparent;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --background-color: #1f2227;
      --text-color: #c0c0c0;
      --hilight-color: #8db4d6;
    }

    /* Some data colors. Light mode uses defaults */
    .highcharts-color-0 {
      fill: #28343f;
      stroke: #84f766;
    }
    .highcharts-color-1 {
      fill: #9696ab;
      stroke: #9696ab;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
  .graph-section {
    &-title {
			display: flex;
			flex-direction: column;
			align-items: flex-start;			

			.info-one {
				font-size: 17px;
				margin-left: 0;
				margin-right: 5px;
			}

			.info-two {
				color: white;
				font-size: 30px;
				margin-right: 10px;
				line-height: 1.1;
			}

			.info-three {
				display: flex;
				font-size: 17px;
			}
		}

		&-date {
			flex-direction: column;
			align-items: center;

			.date-text {
				margin-top: 15px;
			}
		}
  }
}
