@import '../../../sassStyles/mixins';
@import '../../../sassStyles/typography';

.open-orders {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #0D1732;
    margin: auto;
    width: 90rem;
    max-width: 95%;
    border-radius: 10px;
    margin-top: 5px;
    
    &--button {
        width: 100%;
        border: none;
        text-align: center;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-large;

        .arrow {
            margin-left: 10px;
        }
    }

    &--table {
        margin-top: 20px;
    }
}

@include smMinBreakPoint {
    .open-orders {
        &--button {
            font-size: $font-size-less;
        }
    }
}