$font-size-less: 12px;
$font-size-mobile: 15px;
$font-size-small : 16px;
$font-size-medium: 17px;
$font-size-large : 19px;
$font-size-large-more: 21px;

$h1-font-size-mobile: 28px;
$h1-font-size-small : 31px;
$h1-font-size-medium: 33px;
$h1-font-size-large : 36px;