.account-page {
  .ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-align: center;
    line-height: 150px;
    font-family: sans-serif;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

    .loading-span {
      display: block;
      position: absolute;
      top: calc(50% - 2px);
      left: 50%;
      width: 50%;
      height: 4px;
      background: transparent;
      transform-origin: left;
      animation: animate 2s linear infinite;
    }

    .loading-span:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #ffffff;
      top: -6px;
      right: -8px;
      box-shadow: 0 0 20px #ffffff;
    }
  }

  .ring:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #ffffff;
    border-right: 3px solid #ffffff;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
  }

  &--title {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    span {
      color: white;
      font-size: 30px;
    }
  }

  .select-type-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      color: white;
      font-size: 30px;
      margin-left: 20px;
    }
  }
}
